//JAVASCRIPT REQUIRED FOR ENTIRE WEBSITE
let $form, $message, timeoutId;

function captchaSubmit(grecaptchaResponse) {
    try {
        // Considering jQuery.validate is being used as the validation mechanism
        if ($form.valid()) {
            $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

            jQuery.ajax({
                    type: "POST",
                    url: $form.attr("action"),
                    data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse
                })
                .done(function(response) {
                    // Display response message to the user | add success class to the message wrapper
                    $message.slideUp(400, () => {
                        $message
                            .removeClass("error")
                            .addClass("success")
                            .html(response)
                            .slideDown();
                    });
                    // Hide the message after 7 seconds
                    timeoutId && clearTimeout(timeoutId);
                    timeoutId = setTimeout(function() {
                        $message.slideUp(400, () => {
                            $message
                                .html("")
                                .removeClass("success");
                        });
                    }, 7000);
                    // Resetting reCaptcha (v2) if available
                    grecaptcha && grecaptcha.reset();
                    // Uncomment for Submiting Google Analytics

                    if (response.toLowerCase().indexOf('thank') > -1) {
                         gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                             'event_category': 'Form',
                             'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                             'value': 1
                         });
                     }
                    // Reset the form input fields
                    $form.trigger("reset");
                })
                .fail(function(error) {
                    // Display error message to the user | add error class to the message wrapper
                    console.log(error);
                    $message.slideUp(400, () => {
                        $message
                            .removeClass("success")
                            .addClass("error-send-mail")
                            .text(response ? response : "Oops! An error occured")
                            .slideDown();
                    });
                });
        } else {
            grecaptcha && grecaptcha.reset();
        }
    } catch (error) {
        console.log(error);
    }
}

$(document).ready(function() {
    
    $(document).on("submit", 'form', e => {
        e.preventDefault();
        $form = $(e.target);
        $message = $form.find("#form-messages");
        grecaptcha.execute();
        return false;
    });



    var tempHeaderContent = '',
        tempFooterContent = '',
        tempSection = '',
        footerCurVal = $('#FooterSelector').val(),
        footerOldVal = $('#FooterSelector').val(),
        headerCurVal = $('#HeaderSelector').val(),
        headerOldVal = $('#HeaderSelector').val();

    $('html').keypress(function(e) {
        if (e.key.toLowerCase() == 'x') {
            $('#FooterSelectorWrapper').stop().slideToggle();
        }
        if (e.key.toLowerCase() == 'a') {
            $('#HeaderSelectorWrapper').stop().slideToggle();
        }
    });

    $('html').keydown(function(e) {
        var evtobj = window.event ? event : e
        if (evtobj.keyCode == 90 && evtobj.ctrlKey) {
            if (tempSection == '.header-wrapper') {
                $('#HeaderSelector option[value="' + headerOldVal + '"]').prop('selected', true);
                $('.header-wrapper').html(tempHeaderContent);
                console.log('#HeaderSelector option[value="' + headerOldVal + '"]');
            } else {
                $('#FooterSelector option[value="' + footerOldVal + '"]').prop('selected', true);
                $('.footer-wrapper').html(tempFooterContent);
                console.log('#FooterSelector option[value="' + footerOldVal + '"]');
            }
            // $(tempSection+'').val();
            // $(tempSection+'').html(tempContent);
        }
    });

    $('body').on('change', '#FooterSelector', function() {
        $.ajax({
                url: 'inc/footer/' + $(this).val()
            })
            .fail()
            .done(function(data) {
                tempSection = '.footer-wrapper';
                footerOldVal = footerCurVal;
                footerCurVal = $('#FooterSelector').val();
                console.log(footerOldVal);
                $('.footer-wrapper').slideUp(300, function() {
                    tempFooterContent = $(this).html();
                    $(this).html(data).slideDown(300);
                });
            });
        footerOldVal = $(this).val();
    });

    // jQuery.validator.setDefaults({
    //     debug: true,
    //     success: "valid"
    // });


    // $('#contactForm').length > 0 && $('#contactForm').validate();
    // $('#rateRequest').length > 0 && $('#rateRequest').validate();
    // $('#hiringDriver').length > 0 && $('#hiringDriver').validate();
});